import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.tooltip + 100,
    color: "#fff",
  },
}));

export default function PageLoader({ showPageLoader, setShowPageLoader }) {
  const classes = useStyles();

  return (
    <Backdrop
      className={classes.backdrop}
      open={showPageLoader}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
}
