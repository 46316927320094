import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { doRegister, doLogin } from "./../../../store/actions/Users";
import Snackbar from "../../components/SnackBar";
import { HOME_ROUTE, EDIT_PROFILE } from "../../config/routes";
import useUsersStatus from "./../../states/users";
import { useRouter } from "next/router";

export default function WithAuthContainer(WrappedComponent, { type }) {
  const AuthComponent = () => {
    const isLoggesIn = useUsersStatus();
    const dispatch = useDispatch();
    const router = useRouter();
    const usersData = useSelector((state) => {
      return state.UsersReducer;
    });

    const handleSubmit = (values) => {
      if (type === "register") {
        dispatch(doRegister(values));
      } else if (type === "login") {
        dispatch(doLogin(values));
      }
    };

    useEffect(() => {
      if (isLoggesIn) {
        if (type === "register") {
          router.push(EDIT_PROFILE);
        } else {
          router.push(HOME_ROUTE);
        }
      }
    }, [isLoggesIn]);

    return (
      <Container maxWidth="sm">
        <Box my={4}>
          <WrappedComponent
            isLoading={usersData.isLoading}
            handleSubmit={handleSubmit}
          />
        </Box>
        <Snackbar
          severity="error"
          open={!!usersData.errorMessage}
          message={usersData.errorMessage}
        />
      </Container>
    );
  };

  return AuthComponent;
}
