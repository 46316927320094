import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import NextLink from "./../NextLink";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import dynamic from "next/dynamic";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import useTranslation from "next-translate/useTranslation";
import { FORGOT_PASSWORD, SIGN_UP } from "../../config/routes";
import useStyles from "./style";
import SingInForm from "./SignInForm";
import PageLoader from "../PageLoader";

function SignIn({ handleSubmit, isLoading }) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.pageContainer}>
        {isLoading ? <PageLoader showPageLoader={isLoading} /> : null}
        <SingInForm handleSubmit={handleSubmit} isLoading={isLoading} />
        <Grid container>
          <Grid item>
            <NextLink href={SIGN_UP}>
              <Link variant="body2">{t("do_not_have_an_account_sign_up")}</Link>
            </NextLink>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default SignIn;
