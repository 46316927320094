
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import SignIn from "../src/components/Auth";
import WithAuthContainer from "../src/containers/Auth";

const LoginComponent = WithAuthContainer(SignIn, {
  type: "login",
});

const __Page_Next_Translate__ = LoginComponent;

//export const config = { amp: "hybrid" };


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  